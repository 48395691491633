@import url('https://fonts.googleapis.com/css2?family=Fraunces:ital,opsz,wght@0,9..144,100..900;1,9..144,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer component {
  .mapboxgl-popup-content {
    @apply bg-[#002A16] rounded-2xl !important ;
  }
  
  .mapboxgl-popup-tip {
    @apply border-t-[#002A16]  !important;
  }

}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #002A16; 
  border-radius: 16px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2bb24b; 
}